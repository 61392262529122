import React, {useMemo} from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import Input from 'components/Input/Formik/Input';
import FormItem from 'components/Form/FormItem';
import RadioGroup from 'components/Input/Formik/RadioGroup';
import Loader from 'components/Loader';
import './Form.scss';
import {GetWorkgroupsQueryParams, useGetWorkgroups} from "../../hooks/Workgroup/useGetWorkgroup";
import {Field, Form, Formik} from "formik";
import Dropdown from "../Input/Formik/Dropdown";
import * as Yup from 'yup';
import {SoulMeals} from "../../types/SingleEvent.type";
import DietForm from "./DietForm";
import useJobApplicationSubmit from "../../hooks/JobApplication/useJobApplicationSubmit";
import ButtonSpinner from "../Loader/ButtonSpinner";
import Moment from "react-moment";

type VolunteerFormProps = {
    eventSlug: string,
    eventToken: string,
    meals: SoulMeals,
}

const prepareSchema = (t) => {
    return Yup.object().shape({
        firstName: Yup.string()
            .trim()
            .required(t('form.validation.required')),
        preposition: Yup.string()
            .trim(),
        lastName: Yup.string()
            .trim()
            .required(t('form.validation.required')),
        phoneNumber: Yup.string()
            .matches(/^[0-9]+$/, t('form.validation.invalid'))
            .required(t('form.validation.required')),
        email: Yup.string()
            .email(t('form.validation.invalid'))
            .required(t('form.validation.required')),
        dateOfBirth: Yup.string()
            .test('is-valid-date', t('form.validation.invalid'), (value) =>
                moment(value, 'DD-MM-YYYY', true).isValid()
            )
            .required(t('form.validation.required')),
        previouslyActiveAsEmployee: Yup.boolean().required(t('form.validation.required')),
        lastYearActiveAsEmployee: Yup.string()
            .when('previouslyActiveAsEmployee', {
                is: true,
                then: (schema) => schema.matches(/^\d{4}$/, t('form.validation.invalid'))
                    .required(t('form.validation.required')),
                otherwise: (schema) => schema.nullable(),
            }),
        firstPreferredWorkgroup: Yup.string().required(t('form.validation.required')),
        secondPreferredWorkgroup: Yup.string()
            .test(
                'not-one-of',
                t('form.validation.invalid'),
                function (value) {
                    if (value === '0') {
                        return true; // Skip the notOneOf check if the value is '0'
                    }
                    return ![this.resolve(Yup.ref('firstPreferredWorkgroup'))].includes(value);
                }
            )
            .required(t('form.validation.required')),
        thirdPreferredWorkgroup: Yup.string()
            .test(
                'not-one-of',
                t('form.validation.invalid'),
                function (value) {
                    if (value === '0') {
                        return true; // Skip the notOneOf check if the value is '0'
                    }
                    return ![
                        this.resolve(Yup.ref('firstPreferredWorkgroup')),
                        this.resolve(Yup.ref('secondPreferredWorkgroup'))
                    ].includes(value);
                }
            )
            .required(t('form.validation.required')),
        motivation: Yup.string()
            .trim()
            .required(t('form.validation.required')),
        hasDiet: Yup.boolean().required(t('form.validation.required')),
        hasAllergies: Yup.boolean().required(t('form.validation.required')),
        dietaryPreference: Yup.array()
            .when('hasDiet', {
                is: true,
                then: (schema) => schema
                    .min(1, t('form.validation.required'))
                    .required(t('form.validation.required')),
                otherwise: (schema) => schema.nullable(),
            }),
        dietaryRestrictions: Yup.array()
            .when('hasAllergies', {
                is: true,
                then: (schema) => schema
                    .min(1, t('form.validation.required'))
                    .required(t('form.validation.required')),
                otherwise: (schema) => schema.nullable(),
            }),
    });
};

const prepareOptions = (workgroups, t) => {
    const options = workgroups
        .filter((team) => team.workgroups !== null)
        .map((team) => ({
            label: team.name,
            options: team.workgroups.map((workgroup) => ({
                label: workgroup.name,
                value: workgroup.token,
            })),
        }));
    options.unshift({options: [{label: t('volunteer.no_preference'), value: '0'}]});
    return options;
};

const VolunteerForm: React.FC<VolunteerFormProps> = ({eventSlug, eventToken, meals}) => {
    const {t, i18n} = useTranslation();

    const {
        data: workgroups,
        isLoading: isLoading,
        isError: isError,
        error
    } = useGetWorkgroups({eventToken} as GetWorkgroupsQueryParams);

    const options = useMemo(() => prepareOptions(workgroups || [], t), [workgroups, t]);
    const schema = useMemo(() => prepareSchema(t), [t]);
    const {submit, validation, isSaved} = useJobApplicationSubmit(eventToken, t);

    if (isError) {
        if(error.response && error.response.status && error.response.status === 400 && error.response.data.message) {
            return <div>{error.response.data.message}</div>;
        }
        else {
            return <div>{t('error.event')}</div>;
        }
    }

    if (isLoading || workgroups === undefined) {
        return <Loader className="FormLoader"/>;
    }

    if (isSaved === true) {
        return <p>
            {t('volunteer.thanks')}
        </p>;
    }

    const canSpecifyDietaryRequirements = moment().isBefore(moment(meals.dueDate), 'day');

    return (
        <Formik
            initialValues={{
                firstName: '',
                preposition: '',
                lastName: '',
                phoneNumber: '',
                email: '',
                dateOfBirth: '',
                lastYearActiveAsEmployee: '',
                motivation: '',
                firstPreferredWorkgroup: '',
                secondPreferredWorkgroup: '',
                thirdPreferredWorkgroup: '',
                previouslyActiveAsEmployee: "0",
                hasDiet: "0",
                hasAllergies: "0",
                dietaryPreference: [],
                dietaryRestrictions: [],
            }}
            validationSchema={schema}
            onSubmit={submit}
        >
            {({handleChange, handleBlur, values, setFieldValue, isSubmitting}) => (
                <Form>
                    <FormItem label={t('form.label.name')}>
                        <Col md={5}>
                            <Field
                                component={Input}
                                placeholder={t('form.label.firstName')}
                                name="firstName"
                                serverError={validation}
                            />
                        </Col>
                        <Col md={2}>
                            <Field
                                component={Input}
                                placeholder={t('form.label.preposition.short')}
                                name="preposition"
                                serverError={validation}
                            />
                        </Col>
                        <Col md={5}>
                            <Field
                                component={Input}
                                placeholder={t('form.label.lastName')}
                                name="lastName"
                                serverError={validation}
                            />
                        </Col>
                    </FormItem>
                    <FormItem label={t('form.label.email')}>
                        <Col md={12}>
                            <Field
                                component={Input}
                                placeholder={t('form.label.email')}
                                name="email"
                                serverError={validation}
                            />
                        </Col>
                    </FormItem>
                    <FormItem label={t('form.label.phoneNumber')}>
                        <Col md={12}>
                            <Field
                                component={Input}
                                placeholder={t('form.label.phoneNumber')}
                                name="phoneNumber"
                                serverError={validation}
                            />
                        </Col>
                    </FormItem>
                    <FormItem label={t('form.label.birthdate.long')}>
                        <Col md={4}>
                            <Field
                                component={Input}
                                placeholder="DD-MM-YYYY"
                                name="dateOfBirth"
                                mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                serverError={validation}
                            />
                        </Col>
                    </FormItem>

                    <FormItem label={t('form.label.previouslyActiveAsEmployee')}>
                        <Col md={12}>
                            <Field
                                component={RadioGroup}
                                options={[
                                    {
                                        label: t('form.label.yes'),
                                        value: "1",
                                    },
                                    {
                                        label: t('form.label.no'),
                                        value: "0",
                                    },
                                ]}
                                onChange={(e) => {
                                    handleChange(e);

                                    if (e.target.value === false) {
                                        setFieldValue('lastYearActiveAsEmployee', '');
                                    }
                                }}
                                variant="horizontal"
                                name="previouslyActiveAsEmployee"
                                serverError={validation}
                            />
                        </Col>
                    </FormItem>
                    {values.previouslyActiveAsEmployee === "1"
                        && (
                            <FormItem label={t('form.label.lastYearActiveAsEmployee')}>
                                <Col md={2}>
                                    <Field
                                        component={Input}
                                        placeholder="YYYY"
                                        name="lastYearActiveAsEmployee"
                                        serverError={validation}
                                    />
                                </Col>
                            </FormItem>
                        )}
                    <div className="TeamHeading">
                        <h3>{t('volunteer.teams')}</h3>
                        <div className="BlockExplanation">
                            {t('volunteer.which_team')}
                        </div>
                    </div>
                    <FormItem
                        label={`${t('form.label.preference.first')} ${t('form.label.preference.preference')}`}
                    >
                        <div className="PreferenceDropdown">
                            <Field
                                component={Dropdown}
                                options={options}
                                placeholder={`${t('form.label.preference.first')} ${t(
                                    'form.label.preference.preference',
                                )}`}
                                name="firstPreferredWorkgroup"
                                isOptionDisabled={(option) => {
                                    if (option.value === '0') return false;

                                    return option.value === values.secondPreferredWorkgroup || option.value === values.thirdPreferredWorkgroup;
                                }}
                                serverError={validation}
                            />
                        </div>
                    </FormItem>
                    <FormItem
                        label={`${t('form.label.preference.second')} ${t('form.label.preference.preference')}`}
                    >
                        <div className="PreferenceDropdown">
                            <Field
                                component={Dropdown}
                                options={options}
                                placeholder={`${t('form.label.preference.second')} ${t(
                                    'form.label.preference.preference',
                                )}`}
                                name="secondPreferredWorkgroup"
                                isOptionDisabled={(option) => {
                                    if (option.value === '0') return false;

                                    return option.value === values.firstPreferredWorkgroup || option.value === values.thirdPreferredWorkgroup;
                                }}
                                serverError={validation}
                            />
                        </div>
                    </FormItem>
                    <FormItem
                        label={`${t('form.label.preference.third')} ${t('form.label.preference.preference')}`}
                    >
                        <div className="PreferenceDropdown">
                            <Field
                                component={Dropdown}
                                options={options}
                                placeholder={`${t('form.label.preference.third')} ${t(
                                    'form.label.preference.preference',
                                )}`}
                                name="thirdPreferredWorkgroup"
                                isOptionDisabled={(option) => {
                                    if (option.value === '0') return false;

                                    return option.value === values.firstPreferredWorkgroup || option.value === values.secondPreferredWorkgroup;
                                }}
                                serverError={validation}
                            />
                        </div>
                    </FormItem>
                    <FormItem label={t('form.label.motivation')}>
                        <Col md={12}>
                            <Field
                                component={Input}
                                placeholder={t('form.label.motivation')}
                                name="motivation"
                                serverError={validation}
                                type="textarea"
                                rows="5"
                            />
                        </Col>
                    </FormItem>

                    <div className="TeamFooter">
                        <div className="BlockExplanation">
                            {t('volunteer.disclaimer')}
                        </div>
                    </div>

                    <div>
                        <hr/>

                        <h2>{t('personalize.notes.title')}</h2>
                        <div className="BlockExplanation">
                            {canSpecifyDietaryRequirements ? <>
                                {t('personalize.notes.subtitle')} {' '}
                                {t('personalize.notes.diet')} {' '}
                                <Moment date={meals.dueDate} locale={i18n.language} format="D MMMM YYYY"/>.
                            </> : <>
                                {t('personalize.notes.diet_due')}
                            </>}
                        </div>
                    </div>

                    {canSpecifyDietaryRequirements && <DietForm meals={meals} values={values} validation={validation}/>}

                    <Row className="Buttons">
                        <Col md={6}/>
                        <Col md={6}>
                            <div className="ButtonRight">
                                <Button variant="primary" disabled={isSubmitting} type="submit">
                                    {t('button.submit')}
                                    <ButtonSpinner active={isSubmitting}/>
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
}

export default VolunteerForm;